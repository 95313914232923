<template>
  <div class="taskList">
    <div class="headTitle">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{path: '/User/workbench'}"><span>我的工作台</span></el-breadcrumb-item>
        <el-breadcrumb-item><span>待办列表</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div :class="['filter-content', accountType == 'BUY' ? 'BUY' : 'SELL']">
      <el-form inline label-width="83px">
        <el-form-item>
          <el-input v-model="orderNameInput" placeholder="请输入内容" clearable size="mini" style="width: 300px; height: 40px"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            size="small"
            type="primary"
            @click="getProductList"
            style="
              margin-left: 0px;
              margin-right: 40px;
              padding: 7px 10px;
              font-size: 16px;
              width: 96px;
              height: 40px;
            "
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-select v-model="orderTypeSelect" clearable size="mini" style="width: 186px；">
            <el-option v-for="item in statusType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="task-content">
      <div :class="['task-list', accountType == 'BUY' ? 'BUY' : 'SELL']">
        <el-table
          @cell-mouse-enter="mouseEnterTask"
          @cell-mouse-leave="mouseLeaveTask"
          row-class-name="task-row"
          :header-cell-style="{'font-size': '17px', 'text-align': 'center'}"
          :cell-style="{'text-align': 'center', padding: '4px'}"
          class="left-table"
          :data="taskData"
          style="width: 100%"
        >
          <el-table-column prop="num" label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="时间" width="220"> </el-table-column>

          <el-table-column prop="orderSn" label="订单号" width="360"> </el-table-column>
          <el-table-column prop="orderName" label="产品信息" width="260"> </el-table-column>
          <el-table-column prop="actualPrice" label="订单金额" width="170">
            <template slot-scope="scope"> ￥{{ scope.row.actualPrice }} </template>
          </el-table-column>
          <el-table-column prop="orderType" label="订单类型" width="130">
            <template slot-scope="scope"> {{ orderMsg(scope.row.orderType) }}订单 </template>
          </el-table-column>
          <el-table-column prop="orderType" label="信息状态" width="130">
            <template slot-scope="scope"> {{ scope.row.approveStatus == '1' ? '待审核' : '提交信息' }} </template>
          </el-table-column>
          <el-table-column prop="orderStatus" label="订单状态" width="140">
            <template slot-scope="scope">
              <span>{{ orderStatusMsg(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orderStatus" label="操作 ">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="linkTo(scope.row)">处理</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="floot">
          <el-pagination class="pagination" background layout="prev, pager, next" :total="this.Pagetotal" :page-size="10" :current-page="currentPage" @current-change="changeCurrentPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {static4OrderAmt, getMessgeList, mybuyderOrderList, mysellerOrderList} from '../api';
import {permission} from '@/modules/mixin/permission-mixin.js';
export default {
  data() {
    return {
      currentPage: 1,
      Pagetotal: 1,

      orderNameInput: '',
      orderTypeSelect: '',
      statusType: [
        {value: '', label: '全部类型'},
        {value: 'AU', label: '竞卖'},
        {value: 'RE', label: '竞买'},
        {value: 'BI', label: '竞标'}
      ],

      msgData: [],
      userInfo: {},
      taskData: [],
      taskId: ''
    };
  },
  mixins: [permission],
  created() {
    if (localStorage.getItem('MenuType')) this.$store.commit('changeMenuType', localStorage.getItem('MenuType'));
    if (this.accountType === 'BUY') {
      this.getMybuyderOrderList();
    } else {
      this.getMysellerOrderList();
    }
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    // this.getStatic4OrderAmt();
    // this.getMessgeList();
  },
  computed: {
    getDay() {
      return this.$moment().date();
    },
    getTime() {
      return this.$moment().format('YYYY年MM月');
    },
    accountType() {
      return this.$store.getters['getterMenuType'];
    }
  },
  watch: {
    accountType: function(val) {
      // this.getStatic4OrderAmt();
      if (val === 'BUY') {
        this.getMybuyderOrderList();
      } else {
        this.getMysellerOrderList();
      }
    }
  },
  methods: {
    mouseEnterTask(row) {
      this.taskId = row.id;
    },
    mouseLeaveTask() {
      this.taskId = '';
    },
    linkTo(item) {
      if (!this.isPermission(item.contractStatus == 'signing' ? {order_status: 'DQS'} : item, this.accountType == 'BUY' ? 'buyder' : 'sellder')) {
        this.$message({
          message: '当前账号无权限',
          type: 'warning',
          center: true
        });
        return;
      }
      let path = '/User/TransactionDetail';
      let myObj = {
        orderid: item.id,
        orderType: item.orderType,
        customer: this.$store.getters['getterMenuType'] == 'BUY' ? 'buyder' : 'sellder',
        isHandleShow: true,
        tciId: item.contractId,
        orderNum: item.orderSn
      };
      this.$router.push({path, query: myObj});
    },

    orderMsg(status) {
      if (status === 'AU') {
        return '竞卖';
      } else if (status === 'RE') {
        return '竞买';
      } else if (status === 'BI') {
        return '竞标';
      }
    },
    orderStatusMsg(row) {
      if (row.contractStatus == 'signing') return '待签署合同';
      if (row.orderStatus === 'DFH') {
        return '待发货';
      } else if (row.orderStatus === 'DSH') {
        return '待签收';
      } else if (row.orderStatus === 'DKP') {
        return '待开发票';
      } else if (row.orderStatus === 'DZF') {
        return '待支付';
      } else if (row.orderStatus === 'ZFC') {
        return '支付待确认';
      } else if (row.orderStatus === 'END') {
        return '交易完成';
      } else if (row.orderStatus === 'QXC') {
        return '取消待确认';
      } else if (row.orderStatus === 'QXS') {
        return '取消成功';
      } else if (row.orderStatus === 'DQS') {
        return '合同未签署';
      } else if (row.orderStatus === 'DFWQ') {
        return '对方未签署';
      }
    },
    // async getStatic4OrderAmt() {
    //   let res = await static4OrderAmt({
    //     tradeRole: this.accountType == "BUY" ? "buy" : "sell",
    //   });
    //   if (res.errno == 0 && res.data.length) {
    //     this.cardData = res.data;
    //   }
    // },
    // async getMessgeList() {
    //   let res = await getMessgeList({
    //     pageSize: 1,
    //     pageNo: 1,
    //     receiverUserId: this.userInfo.id,
    //   });
    //   if (res.errno == 0) {
    //     this.msgData = res.data.list;
    //   }
    // },
    async getMybuyderOrderList() {
      let res = await mybuyderOrderList({
        pageNum: this.currentPage,
        pageSize: 10,
        orderType: this.orderTypeSelect,
        orderName: this.orderNameInput
      });
      if (res.errno == 0) {
        this.taskData = res.data.orderList;
        this.taskData.map(item => {
          item.actualPrice = (parseFloat(item.actualPrice).toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
          return item;
        });
        this.Pagetotal = res.data.total;
      }
    },
    async getMysellerOrderList() {
      let res = await mysellerOrderList({
        pageNum: this.currentPage,
        pageSize: 10,
        orderType: this.orderTypeSelect,
        orderName: this.orderNameInput
      });
      if (res.errno == 0) {
        this.taskData = res.data.orderList;
        this.taskData.map(item => {
          item.actualPrice = (parseFloat(item.actualPrice).toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
          return item;
        });
        this.Pagetotal = res.data.total;
      }
    },
    async getProductList() {
      if (this.accountType === 'BUY') {
        this.currentPage = 1;
        this.getMybuyderOrderList();
      } else {
        this.currentPage = 1;
        this.getMysellerOrderList();
      }
    },
    changeCurrentPage(val) {
      this.currentPage = val;
      if (this.accountType === 'BUY') {
        this.getMybuyderOrderList();
      } else {
        this.getMysellerOrderList();
      }
    }
  }
};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}

/deep/ .el-button:hover {
  // background: #178aff !important;
  // color: white !important;
  // font-weight: bold;
  font-size: 16px;
  border-color: transparent !important;
}
/deep/ .el-button {
  // background: #eff6ff !important;
  // color: white !important;
  // font-weight: bold;
  font-size: 16px;

  border-color: transparent !important;
}

/deep/ .el-input__inner {
  height: 40px;
  font-size: 16px;
}
.taskList {
  margin-top: 30px;
  position: relative;
}
.headTitle {
  span {
    font-size: 18px;
  }
  position: absolute;
  top: -40px;
}
.floot {
  padding: 30px 0;

  background-color: #fff;
  height: 100px;
  position: relative;
  .pagination {
    position: absolute;
    right: 10px;
  }
}
.filter-content {
  background-color: #fff;
  line-height: 40px;

  padding: 25px 10px 0 25px;
}

.task-content {
  padding: 0 25px 0 25px;

  width: 100%;
  background-color: #fff;
}
.task-list {
  & /deep/ .task-row {
    font-size: 16px;
    &:nth-child(odd) {
      background: linear-gradient(to right, #f0f8fe, #fff);
    }
  }
  & /deep/ td,
  & /deep/ th {
    border: none;
  }
}
</style>
